import { List, Datagrid, TextField, SearchInput } from "react-admin";
import { AuthorField } from "../shared/AuthorField";

export const StockList = () => (
  <List exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="address" />
      <AuthorField source="author"/>
    </Datagrid>
  </List>
);

export default StockList;
