import { Edit, SimpleForm, TextInput, useRecordContext, useTranslate } from "react-admin";


export const StockEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="address" fullWidth/>
      <TextInput source="description" fullWidth />
    </SimpleForm>
  </Edit>
);


export default StockEdit;