import * as React from "react";
import { ReactElement, useState } from "react";
import Box from "@mui/material/Box";
import LabelIcon from "@mui/icons-material/Label";
import AdminIcon from "@mui/icons-material/Settings";
import InsuranceIcon from "@mui/icons-material/AccountBalance";
import InspectionIcon from "@mui/icons-material/NoCrash";
import transactions from "../transaction";
import querystring from "query-string";
import accidents from "../accident";

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  useSavedQueries,
} from "react-admin";

import cities from "../cities";
import users from "../users";
import autoBrands from "../auto-brands";
import cars from "../cars";
import partners from "../partners";
import customers from "../customers";
import orders from "../orders";
import additionalServices from "../additional-services";
import maintenance from "../maintenance";
import maintenanceItem from "../maintenance-item";
import customerSegments from "../customer-segments";
import tariff from "../tariff";
import carTypes from "../car-type";
import acts from "../act";
import SubMenu from "./SubMenu";
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
  SolarMenu,
} from "@react-admin/ra-navigation";
import { DashboardIcon } from "../dashboard";
import { CardContent, Divider, Typography } from "@mui/material";
import SalesIcon from "@mui/icons-material/CurrencyRuble";
import CustomBottomToolbar from "./BottomToolbar";
import { useProfile } from "../profile/Profile";
import transactionAccount from "../transaction-account";
import { OrderTypeEnum } from "../types";
import stocks from "../stocks";

export const bookingFilter = querystring.stringify({
  filter: JSON.stringify({ type: OrderTypeEnum.BOOKING }),
});

export const orderFilter = querystring.stringify({
  filter: JSON.stringify({ type: OrderTypeEnum.ORDER }),
});

export const Menu = () => {
  const { profileVersion } = useProfile();
  return (
    <SolarMenu bottomToolbar={<CustomBottomToolbar />} key={profileVersion}>
      <SolarMenu.DashboardItem />
      <SalesMenuItem />
      <CarsMenuItem />
      <MaintenanceMenuItem />
      <AdminMenuItem />
    </SolarMenu>
  );
};

const SalesMenuItem = (): ReactElement => {
  const translate = useTranslate();
  const savedQueriesMenuItems = usePersistedQueriesMenu("Customer");

  return (
    <SolarMenu.Item
      name="sales"
      icon={<SalesIcon />}
      label="pos.menu.sales"
      subMenu={
        <>
          <Typography variant="h6" gutterBottom ml={1}>
            {translate(`pos.menu.sales`, {
              smart_count: 1,
            })}
          </Typography>
          <SolarMenu.List dense>
            <SolarMenu.Item
              name="sales.customers"
              to="/Customer?filter={}"
              icon={<customers.icon />}
              label={translate(`resources.Customer.name`, {
                smart_count: 2,
              })}
            />
            <SolarMenu.Item
              name="sales.booking"
              to={`/Order?${bookingFilter}`}
              icon={<orders.icon />}
              label={translate(`resources.Booking.name`, {
                smart_count: 2,
              })}
            />
            <SolarMenu.Item
              name="sales.order"
              to={`/Order?${orderFilter}`}
              icon={<orders.icon />}
              label={translate(`resources.Order.name`, {
                smart_count: 2,
              })}
            />
            <Divider />
            <SolarMenu.Item
              name="sales.transaction"
              to="/Transaction?filter={}"
              icon={<transactions.icon />}
              label={translate(`resources.Transaction.name`, {
                smart_count: 2,
              })}
            />
            <Divider />
            <SolarMenu.Item
              name="sales.tariff"
              to="/Tariff"
              icon={<tariff.icon />}
              label={translate(`resources.Tariff.name`, {
                smart_count: 2,
              })}
            />
            <SolarMenu.Item
              name="sales.car_type"
              to="/CarType"
              icon={<carTypes.icon />}
              label={translate(`resources.CarType.name`, {
                smart_count: 2,
              })}
            />
            <SolarMenu.Item
              name="sales.additional_service"
              to="/AdditionalService"
              icon={<additionalServices.icon />}
              label={translate(`resources.AdditionalService.name`, {
                smart_count: 2,
              })}
            />
            <SolarMenu.Item
              name="sales.customer_segments"
              to="/CustomerSegment"
              icon={<customerSegments.icon />}
              label={translate(`resources.CustomerSegment.name`, {
                smart_count: 2,
              })}
            />
          </SolarMenu.List>

          {savedQueriesMenuItems.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom mt={2} ml={1}>
                {translate(`pos.menu.my_queries`, {
                  smart_count: 1,
                })}
              </Typography>
              <SolarMenu.List dense>
                {savedQueriesMenuItems.map(({ label, to }) => (
                  <SolarMenu.Item
                    key={label}
                    name="/Customer"
                    to={to}
                    label={label}
                  />
                ))}
              </SolarMenu.List>
            </>
          )}
        </>
      }
    />
  );
};

const CarsMenuItem = (): ReactElement => {
  const translate = useTranslate();
  const savedQueriesMenuItems = usePersistedQueriesMenu("PartnerCar");
  const savedInsuranceReportQueriesMenuItems =
    usePersistedQueriesMenu("reportInsurance");

  return (
    <SolarMenu.Item
      name="cars"
      icon={<autoBrands.icon />}
      label="pos.menu.cars"
      subMenu={
        <>
          <Typography variant="h6" gutterBottom ml={1}>
            {translate(`pos.menu.cars`, {
              smart_count: 1,
            })}
          </Typography>
          <SolarMenu.List dense>
            <SolarMenu.Item
              name="cars.cars"
              to="/PartnerCar"
              icon={<autoBrands.icon />}
              label={translate(`resources.PartnerCar.name`, {
                smart_count: 2,
              })}
            />
            <SolarMenu.Item
              name="cars.acts"
              to="/Act"
              icon={<acts.icon />}
              label={translate(`resources.Act.name`, {
                smart_count: 2,
              })}
            />
            <SolarMenu.Item
              name="cars.accidents"
              to="/Accident"
              icon={<accidents.icon />}
              label={translate(`resources.Accident.name`, {
                smart_count: 2,
              })}
            />
          </SolarMenu.List>
          {savedQueriesMenuItems.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom mt={2} ml={1}>
                {translate(`pos.menu.my_queries`, {
                  smart_count: 1,
                })}
              </Typography>
              <SolarMenu.List dense>
                {savedQueriesMenuItems.map(({ label, to }) => (
                  <SolarMenu.Item
                    key={label}
                    name="/PartnerCar"
                    to={to}
                    label={label}
                  />
                ))}
              </SolarMenu.List>
            </>
          )}
          <Typography variant="h6" gutterBottom mt={2} ml={1}>
            {translate(`pos.menu.reports.title`, {
              smart_count: 1,
            })}
          </Typography>
          <SolarMenu.List dense>
            <SolarMenu.Item
              name="cars.report.insurance"
              to="/cars/report/insurance"
              icon={<InsuranceIcon />}
              label={translate(`pos.reports.cars.insurance.title`, {
                smart_count: 2,
              })}
            />
            <SolarMenu.Item
              name="cars.report.inspection"
              to="/cars/report/to_inspection"
              icon={<InspectionIcon />}
              label={translate(`pos.reports.cars.inspection.title`, {
                smart_count: 2,
              })}
            />
          </SolarMenu.List>
        </>
      }
    />
  );
};

const MaintenanceMenuItem = (): ReactElement => {
  const translate = useTranslate();
  return (
    <SolarMenu.Item
      name="maintenance"
      icon={<maintenance.icon />}
      label="pos.menu.maintenance"
      subMenu={
        <SolarMenu.List dense>
          <Typography variant="h6" ml={1}>
            {translate(`pos.menu.maintenance`, {
              smart_count: 1,
            })}
          </Typography>
          <SolarMenu.Item
            name="maintenance.maintenance"
            to={"/Maintenance"}
            icon={<maintenance.icon />}
            label={translate(`resources.Maintenance.name`, {
              smart_count: 2,
            })}
          />
          <SolarMenu.Item
            name="maintenance.maintenance_item"
            to={"/MaintenanceItem"}
            icon={<maintenanceItem.icon />}
            label={translate(`resources.MaintenanceItem.name`, {
              smart_count: 2,
            })}
          />
        </SolarMenu.List>
      }
    />
  );
};

const AdminMenuItem = (): ReactElement => {
  const translate = useTranslate();
  const adminLabel = translate(`pos.menu.admin`, {
    smart_count: 2,
  });
  return (
    <SolarMenu.Item
      name="admin"
      icon={<AdminIcon />}
      label={adminLabel}
      subMenu={
        <SolarMenu.List dense>
          <Typography variant="h6" ml={1}>
            {adminLabel}
          </Typography>
          <SolarMenu.Item
            name="admin.users"
            to={"/_User"}
            icon={<users.icon />}
            label={translate(`resources._User.name`, {
              smart_count: 2,
            })}
          />
          <SolarMenu.Item
            name="admin.partners"
            to={"/Partner"}
            icon={<partners.icon />}
            label={translate(`resources.Partner.name`, {
              smart_count: 2,
            })}
          />
          <SolarMenu.Item
            name="admin.cities"
            to={"/City"}
            icon={<cities.icon />}
            label={translate(`resources.City.name`, {
              smart_count: 2,
            })}
          />
          <SolarMenu.Item
            name="admin.stocks"
            to={"/Stock"}
            icon={<stocks.icon />}
            label={translate(`resources.Stock.name`, {
              smart_count: 2,
            })}
          />
          <SolarMenu.Item
            name="admin.autoBrands"
            to={"/AutoBrand"}
            icon={<autoBrands.icon />}
            label={translate(`resources.AutoBrand.name`, {
              smart_count: 2,
            })}
          />
          <SolarMenu.Item
            name="admin.autoModel"
            to={"/AutoModel"}
            icon={<autoBrands.icon />}
            label={translate(`resources.AutoModel.name`, {
              smart_count: 2,
            })}
          />
          <SolarMenu.Item
            name="admin.transactionAccount"
            to={"/TransactionAccount"}
            icon={<transactionAccount.icon />}
            label={translate(`resources.TransactionAccount.name`, {
              smart_count: 2,
            })}
          />
        </SolarMenu.List>
      }
    />
  );
};

const usePersistedQueriesMenu = (
  resource: string
): { label: string; to: string }[] => {
  const [savedQueries] = useSavedQueries(resource);
  const savedQueriesMenuItems = savedQueries.map(({ label, value }) => ({
    label,
    to: `/${resource}?${querystring.stringify({
      filter: JSON.stringify(value.filter),
      sort: value?.sort?.field,
      order: value?.sort?.order,
      page: 1,
      perPage: value.perPage,
      displayedFilters: value.displayedFilters,
    })}`,
  }));

  return savedQueriesMenuItems;
};
