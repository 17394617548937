import * as React from "react";
import { useRef, useEffect, ComponentProps } from "react";
import FullCalendar from "@fullcalendar/react";
import {
  DatesSetArg,
  DateSelectArg,
  EventClickArg,
  EventChangeArg,
  EventInput,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, styled, Theme, SxProps } from "@mui/material";
import {
  useCreatePath,
  useListContext,
  sanitizeListRestProps,
  TransformData,
  useUpdate,
  useNotify,
  useRefresh,
  useSidebarState,
  MutationMode,
  RaRecord,
  UpdateParams,
} from "react-admin";
import isEqual from "lodash/isEqual";
import { UseMutationOptions } from "react-query";

import { getFilterValuesFromInterval as defaultGetFilterValuesFromInterval } from "./getFilterValuesFromInterval";
import { convertToEvent as defaultConvertToEvent } from "./convertToEvent";
import { useNavigate } from "react-router-dom";


export const Calendar = (props: CalendarProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const createPath = useCreatePath();
  const defaultOnSuccess = () => {
    notify("ra.notification.updated", {
      type: "info",
      messageArgs: {
        smart_count: 1,
      },
      undoable: true,
    });
  };
  const defaultOnError = (error: any) => {
    notify(
      typeof error === "string"
        ? error
        : error.message || "ra.notification.http_error",
      { type: "warning" }
    );
    refresh();
  };

  const {
    className,
    getFilterValueFromInterval = defaultGetFilterValuesFromInterval,
    convertToEvent = defaultConvertToEvent,
    mutationMode = "undoable",
    mutationOptions = {
      onSuccess: defaultOnSuccess,
      onError: defaultOnError,
    },
    sx,
    transform,
    onEventClick,
    onDateSelect,
    ...rest
  } = props;

  const { data, filterValues, setFilters, displayedFilters, resource } =
    useListContext();
  const [updateEvent] = useUpdate();
  const calendar = useRef<FullCalendar>();

  // update size when sidebar changes
  const isFirstRender = useRef(true);
  useEffect(() => {
    isFirstRender.current = false;
  }, []);
  const [sidebarIsOpen] = useSidebarState();
  useEffect(() => {
    if (!isFirstRender.current) {
      setTimeout(() => {
        if (calendar.current) {
          calendar.current.getApi().updateSize();
        }
      }, 300); // the max duration of the sidebar transition
    }
  }, [sidebarIsOpen]);

  const handleDatesSet = (dateInfo: DatesSetArg): void => {
    const newFilterValues = getFilterValueFromInterval(dateInfo, filterValues);
    if (!isEqual(filterValues, newFilterValues)) {
      setFilters(newFilterValues, displayedFilters);
    }
  };

  const handleDateSelect = (selectInfo: DateSelectArg): void => {
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    if (onDateSelect) {
      onDateSelect?.(selectInfo);
    } else {
      navigate(`/${resource}/create`, {
        state: {
          record: {
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay,
          },
        },
      });
    }
  };

  const handleEventClick = (clickInfo: EventClickArg): void => {
    if (onEventClick) {
      onEventClick?.(clickInfo);
    } else {
      navigate(createPath({ resource, id: clickInfo.event.id, type: "edit" }));
    }
  };

  const handleEventChange = async (changeInfo: EventChangeArg) => {
    const payload = {
      id: changeInfo.event.id,
      data: transform
        ? await transform(changeInfo.event.toPlainObject())
        : changeInfo.event.toPlainObject(),
    };
    // @ts-ignore
    updateEvent(resource, payload, { ...mutationOptions, mutationMode });
  };

  return (
    <Root className={className} sx={sx}>
      <FullCalendar
        ref={calendar as React.MutableRefObject<FullCalendar | null>}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={false}
        selectMirror={true}
        dayMaxEvents={true}
        navLinks={false}
        datesSet={handleDatesSet}
        select={handleDateSelect}
        events={data?.map(convertToEvent)}
        eventClick={handleEventClick}
        eventChange={handleEventChange} // called for drag-n-drop/resize
        {...sanitizeListRestProps(rest)}
      />
    </Root>
  );
};

const PREFIX = "RaCalendar";

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  padding: "1em",
  // material-ui skin for full calendar
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  "& .fc-toolbar-title": {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
  },
  "& .fc-button": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.action.active,
    borderColor: theme.palette.divider,
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
    textTransform: theme.typography.button.textTransform,
  },
  "& .fc-button-primary:disabled": {
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    color: theme.palette.action.active,
  },
  "& .fc-button-group .fc-button": {
    borderColor: theme.palette.divider,
    color: theme.palette.action.active,
  },
  "& .fc-button:hover": {
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.primary,
  },
  "& .fc-button-primary:not(:disabled):active": {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.text.primary,
  },
  "& .fc-button-group .fc-button-primary:not(:disabled):active": {
    borderColor: theme.palette.divider,
  },
  "& .fc-button-primary:not(:disabled).fc-button-active": {
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    color: theme.palette.action.active,
  },
  "& .fc-button-group .fc-button-primary:not(:disabled).fc-button-active": {
    borderColor: theme.palette.divider,
    color: theme.palette.text.secondary,
  },
  "& .fc-button-primary:not(:disabled):focus": {
    boxShadow: "none",
  },
  "& .fc-button-primary:not(:disabled):active:focus": {
    boxShadow: "none",
  },
  "& .fc-button-primary:not(:disabled).fc-button-active:focus": {
    boxShadow: "none",
  },
  "& .fc-popover": {
    backgroundColor: theme.palette.background.paper,
  },
}));

export interface CalendarProps<RecordType extends RaRecord = RaRecord>
  extends ComponentProps<typeof FullCalendar> {
  className?: string;
  getFilterValueFromInterval?: (
    dateInfo: DatesSetArg,
    filterValues: any
  ) => any;
  convertToEvent?: (event: any) => EventInput;
  mutationMode?: MutationMode;
  mutationOptions?: UseMutationOptions<
    RecordType,
    unknown,
    UpdateParams<RecordType>
  >;
  sx?: SxProps<Theme>;
  transform?: TransformData;
  onEventClick?: (event: EventClickArg) => void;
  onDateSelect?: (selectInfo: DateSelectArg) => void;
}
