import {
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  CheckboxGroupInput,
  List,
  useCreatePath,
  useListContext,
  useLocaleState,
  useTranslate,
} from "react-admin";
import ruLocale from "@fullcalendar/core/locales/ru";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { useCallback } from "react";
import {
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventInput,
} from "@fullcalendar/core";
import { differenceInMilliseconds, parseISO, add, sub, set } from "date-fns";
import {
  CarEvent,
  CarEventTypeEnum,
} from "../../types";
import { CompleteCalendar } from "../../calendar";
import { useNavigate } from "react-router";
import tippy from "tippy.js";
import { carEventTypeChoises } from "../../shared/select";

const getTitle = (event: CarEvent) => {
  switch (event.type) {
    case CarEventTypeEnum.BOOKED:
      return ` ${event.carName} | ${event.payload.customerName}`;
    case CarEventTypeEnum.RENT:
      return `${event.carName} | ${event.payload.customerName} `;
    case CarEventTypeEnum.MAINTENANCE:
      return `${event.carName} | ${event.carBrand} ${event.carModel} `;
    default:
      return `${event.carName} | ${event.carBrand} ${event.carModel} `;
  }
};

const getEventColor = (event: CarEvent) => {
  switch (event.type) {
    case CarEventTypeEnum.BOOKED:
      return "green";
    case CarEventTypeEnum.RENT:
      return "blue";
    case CarEventTypeEnum.MAINTENANCE:
      return "red";
    default:
      return "gray";
  }
};

const customGetFilterValues = (
  dateInfo?: DatesSetArg,
  filterValues: any = {}
): any => {
  const now = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const nowMinus2Month = sub(now, { months: 6 });
  const nowPlus2Months = add(now, { months: 6 });
  const result =
    !dateInfo ||
    (dateInfo.start > nowMinus2Month && dateInfo.end < nowPlus2Months)
      ? {
          ...filterValues,
          start_gte: nowMinus2Month.toISOString(),
          start_lte: nowPlus2Months.toISOString(),
        }
      : {
          ...filterValues,
          start_gte: dateInfo.startStr,
          start_lte: dateInfo.endStr,
        };
  console.log(result);
  return result;
};

export const eventsFilter = [
  <CheckboxGroupInput
    source="type"
    alwaysOn
    choices={carEventTypeChoises}
    label={false}
  />,
];

export const OrdersCalendar = (props: any) => {
  const [locale] = useLocaleState();
  const translate = useTranslate();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

  return (
    <List
      resource="CarEvent"
      {...props}
      pagination={false}
      perPage={1000}
      hasCreate={false}
      exporter={false}
      filters={eventsFilter}
      filterDefaultValues={customGetFilterValues()}
      title={"Главная"}
    >
      <Calendar />
    </List>
  );
};

const Calendar = (props: any) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const createPath = useCreatePath();
  const [locale] = useLocaleState();
  const theme = useTheme();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  const { data: events, isLoading } = useListContext<CarEvent>();

  const handleEventClick = (event: EventClickArg) => {
    const record = event.event.extendedProps.event;
    const { type, payload } = record;
    switch (type) {
      case CarEventTypeEnum.BOOKED:
      case CarEventTypeEnum.RENT:
        navigate(
          createPath({
            resource: "Order",
            id: payload.orderId,
            type: "edit",
          })
        );
        break;
      case CarEventTypeEnum.MAINTENANCE:
        navigate(
          createPath({
            resource: "Maintenance",
            id: payload.maintenanceId,
            type: "edit",
          })
        );
        break;
      default:
        break;
    }
  };

  const convertToEvent = useCallback(
    (event: CarEvent): EventInput => ({
      id: String(event.id),
      title: getTitle(event),
      resourceId: `${event.carId}${event.carName}`,
      start: event.start,
      end: event.end,
      duration: differenceInMilliseconds(
        parseISO(event.end!.toISOString()),
        parseISO(event.start!.toISOString())
      ),
      durationEditable: false,
      editable: false,
      allDay: false,
      backgroundColor: getEventColor(event),
      extendedProps: { event },
      // borderColor: visit.color,
    }),
    [translate]
  );
  return (
    <CompleteCalendar
      CalendarProps={{
        plugins: [interactionPlugin, dayGridPlugin, resourceTimelinePlugin],
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        locales: [ruLocale],
        views: {
          resourceTimelineMonth: {
            type: "resourceTimeline",
            titleFormat: { year: "numeric", month: "long" },
          },
        },
        initialView: "resourceTimelineMonth",
        convertToEvent,
        getFilterValueFromInterval(dateInfo, filterValues) {
          return customGetFilterValues(dateInfo, filterValues);
        },
        locale,
        onEventClick: (event: EventClickArg) => handleEventClick(event),
        onDateSelect: (selectInfo: DateSelectArg) => {},
        resources:
          events?.map((event: CarEvent) => ({
            id: `${event.carId}${event.carName}`,
            title: `${event.carName} | ${event.carBrand} ${event.carModel}`,
            carType: event.carType,
          })) || [],
        height: "auto",
        editable: false,
        slotMinTime: "07:00",
        slotMaxTime: "24:00",
        nowIndicator: true,
        eventTimeFormat: {
          day: "2-digit",
          hour: "numeric",
          minute: "2-digit",
          hour12: false,
        },
        resourceAreaHeaderContent: translate("resources.PartnerCar.name", {
          smart_count: 2,
        }),
        resourceGroupField: "carType",
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "resourceTimelineMonth,dayGridMonth",
        },
        slotLabelFormat: [{ day: "2-digit" }],
        displayEventEnd: true,
        eventDidMount: function (info) {
          let tooltip = tippy(info.el, {
            content: getTitle(info.event.extendedProps.event),
            placement: "top",
            interactive: true,
            arrow: true,
            theme: "material",
            appendTo: document.body,
            allowHTML: true,
            duration: [1, 1],
            animation: "scale-extreme",
          });
        },
      }}
      ListProps={{
        sx: { marginTop: isSmall ? undefined : 0 },
        hasCreate: false,
        exporter: false,
        pagination: false,
        title: " ",
      }}
    ></CompleteCalendar>
  );
};
