import { Create, SimpleForm, TextInput, required } from "react-admin";

export const StockCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput source="name" validate={[required()]} fullWidth />
      <TextInput source="address" fullWidth/>
      <TextInput source="description" fullWidth />
    </SimpleForm>
  </Create>
);

export default StockCreate;