import { Identifier, RaRecord } from "react-admin";

export interface AddToRelationParams<T = any> {
  id: Identifier;
  relationObjects: RaRecord[];
  target: string;
  meta?: any;
}

export interface Pointer {
  className: string;
  id: string;
}

export class ParsePointer implements Pointer {
  readonly className: string;
  readonly id: string;

  constructor(className: string, id: string) {
    this.className = className;
    this.id = id;
  }
}

export interface City extends RaRecord {
  name: string;
}

export interface Stock extends RaRecord {
  name: string;
  description: string;
  address: string;
  author: _User;
}

export enum TransactionDirectionEnum {
  INCOME = "INCOME",
  OUTCOME = "OUTCOME",
}

export interface TransactionType extends RaRecord {
  name: string;
  direction: TransactionDirectionEnum;
  innerName: TransactionInnerNameEnum;
}

export enum TransactionInnerNameEnum {
  OTHER_WRITE_OFF = "OTHER_WRITE_OFF",
  DEPOSIT_RETURN = "DEPOSIT_RETURN",
  FEE_PAYMENT_OUTCOME = "FEE_PAYMENT_OUTCOME",
  SALARY = "SALARY",
  MAINTENANCE = "MAINTENANCE",
  FEE_PAYMENT_INCOME = "FEE_PAYMENT_INCOME",
  OTHER_INCOME = "OTHER_INCOME",
  ORDER_PREPAYMENT = "ORDER_PREPAYMENT",
  ADDITIONAL_SERVICES_PAYMENT = "ADDITIONAL_SERVICES_PAYMENT",
  ORDER_PAYMENT = "ORDER_PAYMENT",
  DEPOSIT = "DEPOSIT",
}

export interface TransactionAccount extends RaRecord {
  name: string;
}

export interface Transaction extends RaRecord {
  date: Date;
  customer?: any;
  account: any;
  type: any;
  direction: TransactionDirectionEnum;
  amount: number;
  description: string;
  payload: any;
}

export type TTariffDataRow = {
  daysFrom: number;
  price: number;
};

export interface Tariff extends RaRecord {
  carType: CarType;
  data: TTariffDataRow[];
  deposit: number;
  overrun: number;
  city: City;
  partner: Partner;
}

export interface AutoBrand extends RaRecord {
  name: string;
  brandId: string;
}

export interface AutoModel extends RaRecord {
  name: string;
  brand: AutoBrand;
}

export interface _User extends RaRecord {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  banned: boolean;
  confirmed: boolean;
  banReason: string;
  roles: string[];
  avatar: Parse.File;
  position?: string;
}

export interface _Role extends RaRecord {
  name: string;
  title: string;
}

export interface Partner extends RaRecord {
  name: string;
  shortName: string;
  fullName: string;
  positionOfHead: string;
  positionReason: string;
  headFullNameNominative: string;
  positionAndFullNameGenitive: string;
  surnameAndInitails: string;
  legalAddress: string;
  actualAddress: string;
  inn: string;
  kpp: string;
  ogrn: string;
  bankAccount: string;
  bankName: string;
  bankCity: City;
  korrAcount: string;
  bik: string;
  type: PartnerTypeEnum;
}

export interface AccountInfo extends RaRecord {
  inn: string;
  kpp: string;
  ogrn: string;
}

export interface CarType extends RaRecord {
  name: string;
}

export interface BankInfo extends RaRecord {
  bankAccount: string;
  bankName: string;
  bankCity: City;
  korrAcount: string;
  bik: string;
}

export interface ImageClass extends RaRecord {
  file: Parse.File;
  main: boolean;
}

export interface PhotoCheckClass extends RaRecord {
  file: Parse.File;
  title: string;
  main: boolean;
}

export interface DocClass extends RaRecord {
  file: Parse.File;
  title: string;
  carId: Identifier;
  source: DocTypeEnum;
}

export enum DocTypeEnum {
  OSAGO = "OSAGO",
  KASKO = "KASKO",
  CUSTOMER = "CUSTOMER",
  AUTOPASSPORT = "AUTOPASSPORT",
  AUTOREGDOC = "AUTOREGDOC",
  AUTOCHECKDOC = "AUTOCHECKDOC",
  PARTNER = "PARTNER",
  OTHER = "OTHER",
  EQUIPMENT = "EQUIPMENT",
  MAINTENANCE = "MAINTENANCE",
  ACCIDENT = "ACCIDENT",
}

export enum ActTypeEnum {
  TRANSFER = "TRANSFER",
  INTERMEDIATE = "INTERMEDIATE",
  FINAL = "FINAL",
}

export interface IAct extends RaRecord {
  date: Date;
  numStr: string;
  orderId: string;
  mileage: number;
  customer: Customer;
  car: PartnerCar;
  manager: _User;
  description: string;
  equipment: CarEquipment[];
  type: ActTypeEnum;
  author: _User;
}

export interface IAccident extends RaRecord {
  date: Date;
  numStr: string;
  car: PartnerCar;
  customer: Customer;
  orderId: string;
  description: string;
  author: _User;
}

export interface PartnerCar extends RaRecord {
  name: string;
  carType: CarType;
  brand: AutoBrand;
  model: AutoModel;
  licensePlateNumber?: string;
  sortNum: number;
  city: City;
  technicalInspectionNumber?: string;
  technicalInspectionUntil?: string;
  osagoNumber?: string;
  osagoDate?: Date;
  kaskoNumber?: string;
  kaskoDate?: Date;
  registrationDocPart?: string;
  registrationDocNumber?: string;
  registrationDocDate?: string;
  registrationDocDepartment?: string;
  passportType?: CarPassortTypeEnum;
  passportPart?: string;
  passportNumber?: string;
  passportDate?: string;
  passportDepartment?: string;
  vehicleType: string;
  bodyType: string;
  bodyColor: string;
  vinNumber: string;
  chassisNumber: string;
  bodyNumber: string;
  engineNumber: string;
  engineCapacity: number;
  enginePower: number;
  fuelType: string;
  tankCapacity: number;
  fuelRequirement: string;
  partner: Partner;
  user: _User;
  currentState: CarStatusEnum;
  daysToOSAGOOut?: number;
  daysToKASKOOut?: number;
  daysToTOOut?: number;
}

export interface UserNotification extends RaRecord {
  createdAt: Date;
  type: UserNotificationTypeEnum;
  isUnRead: boolean;
  title: string;
  description: string;
  payload: any;
}

export enum PartnerTypeEnum {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  IP = "IP",
}

export enum DocTemplateTypeEnum {
  FROM_ORG_TO_ORG = "FROM_ORG_TO_ORG",
  FROM_ORG_TO_INDIVIDUAL = "FROM_ORG_TO_INDIVIDUAL",
  FROM_INDIVIDUAL_TO_ORG = "FROM_INDIVIDUAL_TO_ORG",
  FROM_INDIVIDUAL_TO_INDIVIDUAL = "FROM_INDIVIDUAL_TO_INDIVIDUAL",
  FROM_SELFEMPLOYED_TO_ORG = "FROM_SELFEMPLOYED_TO_ORG",
  FROM_SELFEMPLOYED_TO_INDIVIDUAL = "FROM_SELFEMPLOYED_TO_INDIVIDUAL",
  FROM_IP_TO_INDIVIDUAL = "FROM_IP_TO_INDIVIDUAL",
  FROM_IP_TO_ORG = "FROM_IP_TO_ORG",
  ACT_TRANSFER = "ACT_TRANSFER",
  ACT_INTERMEDIATE = "ACT_INTERMEDIATE",
  ACT_FINAL = "ACT_FINAL",
}

export enum UserNotificationTypeEnum {
  INSURANCE_OSAGO = "INSURANCE_OSAGO",
  INSURANCE_KASKO = "INSURANCE_KASKO",
  MAINTENANCE = "MAINTENANCE",
  TECHNICAL_INSPECTION = "TECHNICAL_INSPECTION",
}

export interface DocTemplate extends RaRecord {
  name: string;
  file: object;
  author: _User;
  type: DocTemplateTypeEnum;
  isActive: boolean;
}

export enum CarPassortTypeEnum {
  PAPER = "PAPER",
  DIGITAL = "DIGITAL",
}

export interface Contact {
  type: ContactTypeEnum;
  value: string;
  description: string;
}

export interface AdditionalService extends RaRecord {
  name: string;
  type: AdditionalServiceTypeEnum;
  rewardType: AdditionalServiceRewardTypeEnum;
  price: number;
  rewardAmount: number;
}

export enum AdditionalServiceTypeEnum {
  BY_DAY = "BY_DAY",
  ONE_TIME = "ONE_TIME",
  PERCENT = "PERCENT",
}

export enum AdditionalServiceRewardTypeEnum {
  FIX = "FIX",
  PERCENT = "PERCENT",
}

export enum ContactTypeEnum {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  WEBSITE = "WEBSITE",
  VK = "VK",
  TELEGRAM = "TELEGRAM",
  TIKTOK = "TIKTOK",
  OK = "OK",
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
}

export enum RelationshipEnum {
  HUSBAND = "HUSBAND",
  WIFE = "WIFE",
  MOTHER = "MOTHER",
  DAD = "DAD",
  SON = "SON",
  DAUGHTER = "DAUGHTER",
  BROTHER = "BROTHER",
  SISTER = "SISTER",
  UNCLE = "UNCLE",
  AUNT = "AUNT",
  GRANDFATHER = "GRANDFATHER",
  GRANDMOTHER = "GRANDMOTHER",
  FRIEND = "FRIEND",
  GIRLFRIEND = "GIRLFRIEND",
  FATHER_IN_LAW = "FATHER_IN_LAW",
  MOTHER_IN_LAW = "MOTHER_IN_LAW",
  FATHER_IN_LAW2 = "FATHER_IN_LAW2",
  MOTHER_IN_LAW2 = "MOTHER_IN_LAW2",
  COUSIN_BROTHER = "COUSIN_BROTHER",
  COUSIN_SISTER = "COUSIN_SISTER",
  DISTANT_RELATIVE = "DISTANT_RELATIVE",
  OTHER = "OTHER",
}

export enum ExtraDocTypeEnum {
  PASSPORT_SNG = "PASSPORT_SNG",
  PASSPORT_WW = "PASSPORT_WW",
  MILITARY_ID = "MILITARY_ID",
  OTHER = "OTHER",
}

export enum BodyTypeEnum {
  SEDAN = "SEDAN",
  HATCHBACK = "HATCHBACK",
  STATION_WAGON = "STATION_WAGON",
  PICKUP_TRUCK = "PICKUP_TRUCK",
  VAN = "VAN",
  MINIVAN = "MINIVAN",
  CABRIOLET = "CABRIOLET",
  SUV = "SUV",
  LIMOUSINE = "LIMOUSINE",
  COUPE = "COUPE",
  CROSSOVER = "CROSSOVER",
}

export enum VehicleTypeEnum {
  CAR = "CAR",
  TRUCK = "TRUCK",
  SPECIAL = "SPECIAL",
}

export enum FuelTypeEnum {
  GASOLINE = "GASOLINE",
  GAS = "GAS",
  DIESEL = "DIESEL",
  HYBRID = "HYBRID",
  EV = "EV",
}

export enum CustomerTypeEnum {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
}

export enum GenderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum UnitTypeEnum {
  PIECE = "PIECE",
  KG = "KG",
  LITRE = "LITRE",
  HOUR = "HOUR",
}

export enum CarStatusEnum {
  FREE = "FREE",
  RENT = "RENT",
  BOOKED = "BOOKED",
  MAINTENANCE = "MAINTENANCE",
  CHECK = "CHECK",
}

export enum TransmissionTypeEnum {
  AT = "AT",
  MT = "MT",
  RT = "RT",
}

export enum DriveTypeEnum {
  FWD = "FWD",
  RWD = "RWD",
  "4WD" = "4WD",
}

export enum WheelDriveEnum {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

export enum CarColors {
  BLACK = "BLACK",
  GREY = "GREY",
  SILVER = "SILVER",
  WHITE = "WHITE",
  DARK_BLUE = "DARK_BLUE",
  BLUE = "BLUE",
  GREEN = "GREEN",
  BURGUNDY = "BURGUNDY",
  RED = "RED",
  ORANGE = "ORANGE",
  PINK = "PINK",
  BEIGE = "BEIGE",
  YELLOW = "YELLOW",
  GOLDEN = "GOLDEN",
  BROWN = "BROWN",
  PURPLE = "PURPLE",
}

export enum MaintenanceStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
}

export enum BookingSourceEnum {
  MOBILE = "MOBILE",
  OFFICE = "OFFICE",
  PHONE = "PHONE",
  SITE = "SITE",
}

export enum MaintenanceItemTypeEnum {
  SERVICE = "SERVICE",
  SPARE_PART = "SPARE_PART",
  MATERIAL = "MATERIAL",
}

export interface Customer extends RaRecord {
  phone: string;
  firstname: string;
  lastname: string;
  surname: string;
  fullname: string;
  contacts: Contact[];
  type: CustomerTypeEnum;
  birthDate: string;
  birthPlace: string;
  gender: GenderEnum;
  passportNum: string;
  passportSerie: string;
  passportDepartment: string;
  passportDate: string;
  passportDateUntil: string;
  passportCountry: Country;
  drivingLicenseDate: Date;
  createdAt: Date;
}

export interface Country extends RaRecord {
  name: string;
}

export interface MaintenanceItem extends RaRecord {
  name: string;
  type: MaintenanceItemTypeEnum;
  body: string;
  brand: any;
  model: any;
  author: _User;
  manufacturer: string;

  sku: string;
  price: number;
  unitType: UnitTypeEnum;
}

export interface CarEquipment extends RaRecord {
  car: PartnerCar;
  equipment: [];
  author: _User;
  date: Date;
}

export type MaintenanceDataItem = {
  date: string;
  item: string;
  sku: string;
  shop: string;
  amount: number;
  price: number;
  description: string;
  unitType: UnitTypeEnum;
};

export interface Maintenance extends RaRecord {
  start: Date;
  end?: Date;
  car: PartnerCar;
  data: MaintenanceDataItem[];
  description?: string;
  amount: number;
  status: MaintenanceStatusEnum;
  technician: _User;
}

export type AdditionalDatatem = {
  item: string;
  amount: number;
  price: number;
};

export enum CarEventTypeEnum {
  RENT = "RENT",
  BOOKED = "BOOKED",
  MAINTENANCE = "MAINTENANCE",
}

export interface CarEvent extends RaRecord {
  start: Date;
  end: Date;
  car: PartnerCar;
  carId: string;
  carBrand: string;
  carModel: string;
  carType: string;
  carNumber: string;
  carName: string;
  payload: any;
  type: CarEventTypeEnum;
}

export interface Order extends RaRecord {
  start: Date;
  end?: Date;
  car: PartnerCar;
  customer: Customer;
  tariff: Tariff;
  source: BookingSourceEnum;
  additionalData: AdditionalDatatem[];
  chargeInfo?: ICalculateOrderSummaryResponse;
  description?: string;
  total: number;
  delivery: number;
  manager: _User;
  numStr: string;
  num: number;
  paid: boolean;
  isClosed: boolean;
  type: OrderTypeEnum;
}

export enum OrderTypeEnum {
  ORDER = "ORDER",
  BOOKING = "BOOKING",
}

export interface ICalculateBookingSummaryResponse {
  charge?: number;
  overrun?: number;
  days?: number;
  deposit?: number;
  dayPrice?: number;
  total?: number;
  additionalServices?: number;
}

export interface ICalculateOrderSummaryResponse
  extends ICalculateBookingSummaryResponse {
  delivery?: number;
}

export type ICalculateBookingSummaryRequestAdditionalData = {
  item: string;
  price: number;
};

export interface ICalculateBookingSummaryRequest {
  start: string;
  end?: string;
  tariffId: string;
  overrun?: number;
  additionalData?: ICalculateBookingSummaryRequestAdditionalData[];
}

export interface ICalculateOrderSummaryRequest
  extends ICalculateBookingSummaryRequest {
  delivery: number;
}

export interface ICalculateCloseOrderSummaryRequest {
  orderId: string;
  closeAt: string;
}

export interface ICloseOrderInfoResponse {
  days: number;
  total: number;
  charged: number;
  servicesCharged: number;
  dayPrice: number;
  deliveryPrice: number;
  deposit: number;
  payments: number;
}

export enum EquipmentConditionEnum {
  GOOD = "GOOD",
  NORMAL = "NORMAL",
  BAD = "BAD",
}

export enum CarSideEnum {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  FRONT = "FRONT",
  REAR = "REAR",
  INSIDE = "INSIDE",
  OTHER = "OTHER",
}
