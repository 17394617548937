import StockIcon from '@mui/icons-material/Store';

import StockList from './StockList';
import StockCreate from './StockCreate';
import StockEdit from './StockEdit';
import { Stock } from '../types';
import { DataProvider, GetListParams, GetManyReferenceParams } from 'react-admin';

const resource = {
    list: StockList,
    create: StockCreate,
    edit: StockEdit,
    icon: StockIcon,
    recordRepresentation: "name",
};

export const stockLifecycleCallbacks = {
  resource: "Stock",

  
  afterRead: async (record: Stock, dataProvider: DataProvider) => {
    return {
      ...record,
      author: record.author?.id,
    };
  },

};


export default resource;